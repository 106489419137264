import { Box, Typography } from '@mui/material'
import { Asset } from 'contentful'
import Image from 'next/image'
import { FunctionComponent } from 'react'
import { nantes } from 'lib/fonts'
import { colors } from 'lib/root'
import theme from 'lib/theme'
import BookButtonRow from 'components/BookButtonRow'
import CTAButton from 'components/CTAButton'

interface Props {
  name: string
  route: string
  image: Asset
  description: string
  badge?: JSX.Element
  mdUp: boolean
}

export const ServiceItem: FunctionComponent<Props> = ({
  name,
  route,
  image,
  description,
  badge,
  mdUp,
}) => (
  <article>
    <Box
      sx={{
        position: 'relative',
        height: { xs: 327, sm: 385, md: 395, xl: 610 },
      }}
    >
      {badge}
      <Image
        src={'https:' + image.fields.file.url}
        alt={image.fields.title}
        fill
        style={{ objectFit: 'cover', objectPosition: '0 40%' }}
        sizes={`(max-width: ${theme.breakpoints.values.sm}px) 100vw, (max-width: ${theme.breakpoints.values.lg}px) 50vw, 25vw`}
      />
    </Box>
    <Typography
      component="h3"
      variant="h4"
      sx={{
        fontSize: { xs: '1.5rem', md: '2rem' },
        color: colors.core.copper[600],
        pt: 3,
        pb: { xs: 1, md: 2 },
      }}
    >
      {name}
    </Typography>
    <Typography
      sx={{
        fontFamily: nantes.style.fontFamily,
        pb: 3,
        maxWidth: { sm: 290, xl: 'none' },
        minHeight: { sm: 84, md: 96 },
      }}
    >
      {description}
    </Typography>
    {mdUp ? (
      <CTAButton href={route} aria-label={`Book ${name}`}>
        Book
      </CTAButton>
    ) : (
      <BookButtonRow url={route} />
    )}
  </article>
)
