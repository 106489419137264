import store from 'store2'
import { UtmParams } from 'lib/gtm'

const useUtmParams = () => {
  const utmParams: UtmParams = store.get('utmParams') || {}

  return utmParams
}

export default useUtmParams
