import {
  Box,
  Container,
  List,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Asset } from 'contentful'
import Image from 'next/image'
import pluralize from 'pluralize'
import { FunctionComponent, ReactNode, useEffect, useRef } from 'react'
import { colors } from 'lib/root'
import { toSxArray } from 'lib/utils'
import Link from './Link'
import TendListItemButton from './TendListItemButton'

interface Props {
  children: ReactNode
  image: Asset
  markets: {
    name: string
    slug: string
    numberOfStudios: number
    bookingEnabled: boolean
  }[]
  className?: string
  sx?: SxProps<Theme>
}

const StudioLocationModule: FunctionComponent<Props> = ({
  children,
  image,
  markets,
  className,
  sx,
}) => {
  const containerRef = useRef(null)
  const imageRef = useRef(null)

  const adjustImagePosition = () => {
    const computedStyle = window.getComputedStyle(containerRef.current)
    const marginLeft = parseFloat(computedStyle.marginLeft)
    const paddingLeft = parseFloat(computedStyle.paddingLeft)

    const totalOffset = marginLeft + paddingLeft

    imageRef.current.style.left = `-${totalOffset}px`
    imageRef.current.style.position = 'relative'
  }

  useEffect(() => {
    adjustImagePosition()

    window.addEventListener('resize', adjustImagePosition)

    return () => {
      window.removeEventListener('resize', adjustImagePosition)
    }
  }, [])

  return (
    <Container
      component="section"
      maxWidth="xl"
      ref={containerRef}
      className={className}
      sx={[
        {
          mb: { xs: 8, md: 0 },
        },
        ...toSxArray(sx),
      ]}
    >
      <Grid container justifyContent="space-between">
        <Grid
          sm={6}
          xl
          sx={{
            height: image.fields.file.details.image.height,
            maxHeight: { xs: 750, xl: 1000 },
          }}
        >
          <Box
            ref={imageRef}
            sx={{
              position: 'relative',
              height: '100%',
              width: '100%',
            }}
          >
            <Image
              src={'https:' + image.fields.file.url}
              alt={image.fields.title}
              fill
              style={{ objectFit: 'cover', objectPosition: 'top right' }}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          sx={{
            maxWidth: { sm: 544 },
            pt: { xl: 14 },
          }}
        >
          {children}
          {markets?.length > 0 && (
            <List
              disablePadding
              id="markets-list"
              sx={{
                borderTop: `1px solid ${colors.core.grey[400]}`,
                '& a': {
                  p: 0,
                },
              }}
            >
              {markets.map((market) => (
                <li key={market.slug}>
                  <TendListItemButton
                    disableGutters
                    divider
                    smallArrow
                    href={`/studios/${encodeURIComponent(market.slug)}`}
                  >
                    <ListItemText
                      disableTypography
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        my: 2.5,
                      }}
                      primary={
                        <Typography
                          component="h3"
                          variant="h5"
                          sx={{ fontSize: { xs: '1.25rem', xl: '1.5rem' } }}
                        >
                          {market.name}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          sx={[
                            {
                              fontSize: '1rem !important',
                              color: colors.core.blue[800],
                            },
                          ]}
                        >
                          {market.bookingEnabled
                            ? pluralize('studio', market.numberOfStudios, true)
                            : 'Coming soon'}
                        </Typography>
                      }
                    />
                  </TendListItemButton>
                </li>
              ))}
            </List>
          )}
          <Link href="/studios" sx={{ display: 'block', pt: 2.5 }}>
            See our studios
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

export default StudioLocationModule
