import { Box, SxProps } from '@mui/material'
import { phoneNumbers } from 'lib/phoneNumbers'
import { toSxArray } from 'lib/utils'
import CTAButton from 'components/CTAButton'

const buttonStyles: SxProps = {
  py: 1.5,
  px: 2.25,
  height: 48,
  mr: 1,
  '& span': {
    display: 'block',
  },
}

const BookButtonRow = ({
  url = '',
  id = '',
  onlineCtaStyles = [],
  callCtaStyles = [],
}) => (
  <Box id={id} display="flex">
    <CTAButton
      {...(url ? { href: url } : undefined)}
      sx={[buttonStyles, ...toSxArray(onlineCtaStyles)]}
    >
      Book Online
    </CTAButton>
    <CTAButton
      variant="outlined"
      href={`tel:${phoneNumbers.booking}`}
      aria-label="Call to Book"
      sx={[buttonStyles, ...toSxArray(callCtaStyles)]}
    >
      Call to Book
    </CTAButton>
  </Box>
)

export default BookButtonRow
