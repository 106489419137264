import { CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import { FunctionComponent, useContext } from 'react'
import { AppContext } from 'components/contexts/AppContext'

type MobileLoadingIndicatorProps = {
  size?: number
  children: React.ReactElement
  isShown?: boolean
  indicatorColor?: string
}

const MobileLoadingIndicator: FunctionComponent<
  MobileLoadingIndicatorProps
> = ({ size, children, isShown, indicatorColor }) => {
  const { isWebView } = useContext(AppContext)
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('md'))

  if (isShown && (isWebView || smDown)) {
    return (
      <CircularProgress
        size={size}
        disableShrink
        tabIndex={-1}
        sx={[
          indicatorColor && {
            '&.MuiCircularProgress-colorPrimary': {
              color: indicatorColor,
            },
          },
        ]}
      />
    )
  }

  return children
}

export default MobileLoadingIndicator
