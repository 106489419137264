import { Grid, Typography } from '@mui/material'
import { nantes } from 'lib/fonts'
import { colors } from 'lib/root'

const contentHeading = {
  borderTop: `1px solid ${colors.core.grey[400]}`,
  pt: { xs: 3, sm: 2, md: 3 },
  pb: { xs: 1, sm: 2.5, md: 2 },
  pl: { sm: 0 },
  pr: { sm: 2.5, md: 0 },
  color: colors.core.grey[700],
  fontWeight: 'bold',
}

interface Props {
  list: { heading: string; body: string }[]
  even?: boolean
  textLimit?: boolean
}

const RTBList = ({ list, even = false, textLimit = false }: Props) => (
  <Grid container spacing={3} pb={3}>
    {list.map((reason) => (
      <Grid
        item
        xs={12}
        sm={even ? 6 : 4}
        lg={even ? 3 : 4}
        key={reason.heading}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            ...contentHeading,
            fontSize: {
              xs: even ? '1.25rem' : '1.5rem',
              sm: '1.5rem',
            },
          }}
        >
          {reason.heading}
        </Typography>
        <Typography
          sx={{
            fontFamily: nantes.style.fontFamily,
            pr: { sm: 2.5, md: 0 },
            ...(textLimit && { maxWidth: 270 }),
          }}
        >
          {reason.body}
        </Typography>
      </Grid>
    ))}
  </Grid>
)

export default RTBList
