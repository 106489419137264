import { Asset } from 'contentful'
import { NextSeo } from 'next-seo'
import { MetaTag } from 'next-seo/lib/types'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'

interface Props {
  title?: string
  metaTags?: MetaTag[]
  metaDescription?: string
  metaImage?: Asset
  noindex?: boolean
  nofollow?: boolean
}

const Meta: FunctionComponent<Props> = ({
  title = 'Tend',
  metaTags = [],
  metaDescription,
  metaImage = null,
  noindex = false,
  nofollow = false,
}) => {
  const router = useRouter()

  return (
    <NextSeo
      title={title}
      description={metaDescription}
      openGraph={{
        title,
        url: `https://www.hellotend.com${router.asPath}`,
        description: metaDescription,
        ...(metaImage && {
          images: [
            {
              url: `https:${metaImage.fields.file.url}`,
              alt: metaImage.fields.title,
            },
          ],
        }),
      }}
      additionalMetaTags={metaTags}
      noindex={noindex}
      nofollow={nofollow}
    />
  )
}

export default Meta
