import { Box, ListItemButton, ListItemButtonProps } from '@mui/material'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import MobileLoadingIndicator from 'components/MobileLoadingIndicator'
import Link from '../components/Link'
import CheckCircle from './icons/CheckCircle'
import LeftArrow from './icons/LeftArrow'

// Adapted from comment on https://stackoverflow.com/questions/52703321/make-some-properties-optional-in-a-typescript-type to make href not required
type Diff<T, U> = T extends U ? never : T

type RequiredExceptFor<T, TOptional extends keyof T> = Pick<
  T,
  Diff<keyof T, TOptional>
> &
  Partial<T>

interface Props
  extends RequiredExceptFor<ListItemButtonProps<typeof Link>, 'href'> {
  arrow?: boolean
  smallArrow?: boolean
  rotateArrow?: number
  disableLoadingIndicator?: boolean
  alwaysEnabled?: boolean
  onClick?: (e: any | undefined) => void
  component?: any
}

const TendListItemButton: FunctionComponent<Props> = ({
  children,
  href,
  arrow = false,
  smallArrow = false,
  rotateArrow = 180,
  disableLoadingIndicator = false,
  selected,
  alwaysEnabled,
  onClick,
  component,
  ...other
}) => {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = useCallback(
    (e) => {
      if (!disableLoadingIndicator) {
        setIsClicked(true)
      }
      onClick?.(e)
    },
    [onClick, disableLoadingIndicator],
  )

  const icon = useMemo(() => {
    if (selected && !disableLoadingIndicator) {
      return <CheckCircle role="img" />
    }

    return (
      <Box>
        <MobileLoadingIndicator isShown={isClicked}>
          <LeftArrow
            rotate={rotateArrow}
            size={smallArrow ? 'sm' : 'md'}
            role="img"
          />
        </MobileLoadingIndicator>
      </Box>
    )
  }, [disableLoadingIndicator, isClicked, rotateArrow, selected, smallArrow])

  return (
    <ListItemButton
      href={href}
      component={component ? component : href ? Link : undefined}
      selected={selected}
      onClick={handleClick}
      disabled={alwaysEnabled ? false : isClicked}
      {...other}
    >
      {children}
      {(arrow || smallArrow) && icon}
    </ListItemButton>
  )
}

export default TendListItemButton
