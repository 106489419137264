import { SvgIcon, SvgIconProps } from '@mui/material'
import { FunctionComponent } from 'react'
import { colors } from 'lib/root'
import { toSxArray } from 'lib/utils'

const CheckCircle: FunctionComponent<SvgIconProps> = ({ sx, ...other }) => {
  return (
    <SvgIcon
      viewBox="0 0 32 32"
      sx={[
        {
          width: 32,
          height: 32,
        },
        ...toSxArray(sx),
      ]}
      {...other}
    >
      <circle
        cx="16"
        cy="16"
        r="11.25"
        stroke={other.htmlColor || colors.core.grey[700]}
        strokeWidth="1.5"
        fill="none"
      />
      <path
        d="M13 16.2194L14.8947 17.9502L19 14.2002"
        stroke={other.htmlColor || colors.core.grey[700]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        fill="none"
      />
    </SvgIcon>
  )
}

export default CheckCircle
