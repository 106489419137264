import { Box, SxProps, Theme, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { phoneNumbers } from 'lib/phoneNumbers'
import { colors } from 'lib/root'
import { toSxArray } from 'lib/utils'

type Props = {
  color?: string
  phoneNumber?: string
  sx?: SxProps<Theme>
}

const OrCall: FunctionComponent<Props> = ({ color, phoneNumber, sx }) => (
  <Box
    sx={[
      {
        textAlign: 'center',
        lineHeight: 1.15,
        mt: { xs: 2, md: 1.25 },
        mx: 2,
        color: color || colors.core.green[700],
      },
      ...toSxArray(sx),
    ]}
  >
    <Typography
      component="span"
      sx={{
        lineHeight: 1.4375,
        fontSize: '1rem !important',
        letterSpacing: '0.12em',
        textTransform: 'uppercase',
        '& a': {
          bgcolor: 'inherit',
          color: 'inherit',
        },
      }}
    >
      Or call: <strong>{phoneNumber || phoneNumbers.booking}</strong>
    </Typography>
  </Box>
)

export default OrCall
