import {
  Box,
  Container,
  Theme,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { InferGetStaticPropsType } from 'next'
import { OrganizationJsonLd, SocialProfileJsonLd } from 'next-seo'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useContext } from 'react'
import { APP_STORE_URL, route } from 'lib/constants'
import { marketingClient } from 'lib/contentful'
import { defaultRevalidateSeconds } from 'lib/env'
import { nantes } from 'lib/fonts'
import { phoneNumbers } from 'lib/phoneNumbers'
import { colors } from 'lib/root'
import theme, { darkTheme } from 'lib/theme'
import {
  TypeHomepageFields,
  TypeMarketFields,
  TypeStudioFields,
} from 'lib/types/ctf-marketing'
import { groupBy } from 'lib/utils'
import zIndexes from 'lib/zIndexes'
import BookButtonRow from 'components/BookButtonRow'
import CTAButton from 'components/CTAButton'
import InsuranceCheckerDialog from 'components/InsuranceCheckerDialog'
import Link from 'components/Link'
import Meta from 'components/Meta'
import OrCall from 'components/OrCall'
import PromoBadge from 'components/PromoBadge'
import RTBList from 'components/RTBList'
import ScrollAnimate from 'components/ScrollAnimate'
import { ServiceItem } from 'components/ServiceItem'
import StudioLocationModule from 'components/StudioLocationModule'
import { AppContext } from 'components/contexts/AppContext'
import Layout from 'components/layouts/Layout'
import patternGreen from '../public/pattern_green.png'
import appleAppStoreBadge from '/public/apple-app-store-badge-light.svg'

const heroContainerMaxHeight = 960

const ctaStyles = {
  maxHeight: `64px !important`,
  fontSize: '1.125rem !important',
}

const headerCtaStyles = {
  backgroundColor: colors.core.copper[600],
  '&:hover': {
    backgroundColor: '#AD794B',
  },
  '&:active': {
    backgroundColor: '#CA8E59',
  },
}

const headerSecondaryCtaStyles = {
  color: colors.core.copper[600],
  borderColor: colors.core.copper[600],
  '&:hover': {
    backgroundColor: '#AD794B',
    borderColor: colors.core.copper[600],
  },
  '&:active': {
    backgroundColor: '#CA8E59',
    borderColor: colors.core.copper[600],
  },
}

const baseHeadingStyles = {
  color: colors.core.copper[600],
  fontSize: { xs: '2rem', md: '2.5rem' },
  py: 3,
}

const baseBodyStyles = {
  fontFamily: nantes.style.fontFamily,
  fontSize: { xs: '1.25rem', md: '1.5rem' },
  color: colors.core.grey[700],
}

const thickBorderStyles = {
  borderTop: `3px solid ${colors.core.grey[700]}`,
}

const StudioImageCarousel = dynamic(
  () => import('../components/StudioImageCarousel'),
)

const InsuranceChecker = dynamic(() => import('../components/InsuranceChecker'))

const ReviewsModule = dynamic(() => import('../components/ReviewsModule'))

const ImageGrid = dynamic(() => import('../components/ImageGrid'))

const Hero = ({ content }: { content: TypeHomepageFields }) => {
  const mdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  return (
    <Box
      component="section"
      id="hero"
      sx={{
        position: 'relative',
        zIndex: zIndexes.section,
        pb: { lg: 10 },
      }}
    >
      <Box
        component="header"
        sx={{
          height: { xs: 650, md: 720, xl: heroContainerMaxHeight },
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            display: { md: 'grid' },
            gridTemplateColumns: { md: '1fr 1fr' },
            '& video, & img': {
              position: { xs: 'absolute', md: 'relative' },
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              objectFit: 'cover',
              // These numbers need to change if heroContents' height changes
              top: {
                xs: 290,
                md: 0,
                xl: `calc((${heroContainerMaxHeight}px - 100%) / 2)`,
              },
              height: { xs: 'calc(100% - 290px)', md: '100%' },
            },
          }}
        >
          <Container
            maxWidth="xl"
            disableGutters
            sx={(theme) => ({
              margin: {
                md: 'auto 0 auto 64px',
                xl: `278px 0 auto calc((100% - ${theme.breakpoints.values.xl}px / 2) + 64px)`,
              },
              width: { md: 'auto' },
            })}
          >
            <Box
              sx={{
                pt: { xs: 5, sm: 3 },
                px: 3,
                pb: { xs: 4, md: 0 },
                pl: { sm: 0 },
                ml: { sm: 3, md: 0 },
              }}
            >
              <Typography
                component="h1"
                variant="h1"
                sx={{
                  color: colors.core.green[600],
                  mb: 2.5,
                  fontSize: { xs: '2.5rem', md: '4rem' },
                  maxWidth: { xs: 340, md: 560 },
                }}
              >
                {content.heroHeading}
              </Typography>
              <Typography
                component="h2"
                variant="h5"
                sx={{
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  mb: { xs: 3.5, md: 8 },
                }}
              >
                {content.heroSubheading}
              </Typography>
              <Box sx={{ maxWidth: 355 }}>
                {mdUp ? (
                  <>
                    <CTAButton
                      color="warning"
                      fullWidth
                      long
                      sx={{ ...ctaStyles, ...headerCtaStyles }}
                      id="book-online-button"
                    />
                    <OrCall />
                  </>
                ) : (
                  <BookButtonRow
                    id="top-button-row"
                    onlineCtaStyles={[headerCtaStyles]}
                    callCtaStyles={[headerSecondaryCtaStyles]}
                  />
                )}
              </Box>
            </Box>
          </Container>
          <div key="hero-container">
            {mdUp ? (
              <video
                autoPlay
                controls
                muted
                loop
                playsInline
                src={content.heroVideo.fields.file.url}
              />
            ) : (
              <Box
                component="img"
                src={content.mobileHeroImage.fields.file.url}
                alt={content.mobileHeroImage.fields.title}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  mt: { xs: 4.5, sm: 0 },
                }}
              />
            )}
          </div>
        </Box>
      </Box>
    </Box>
  )
}

const Index = ({
  content,
  banner,
  markets,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { email } = useContext(AppContext)

  const largePhoneUp = useMediaQuery('@media (min-height: 480px)')
  const smUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const mdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  const services = [
    {
      name: 'Dental Exam',
      route: `${route.markets}?service=CLNCHK`,
      image: content.servicesImages[0],
      description: 'Cleaning, x-rays, & oral exam.',
    },
    {
      name: 'Orthodontics',
      route: route.smileStraighteningConsult,
      image: content.servicesImages[1],
      description: 'Breezy Braces® & Invisalign.',
      badge: (
        <PromoBadge
          text={content.orthoBadgeText}
          discount={content.orthoDiscount}
        />
      ),
    },
    {
      name: 'Procedures',
      route: route.procedures,
      image: content.servicesImages[2],
      description: 'Fillings, implants, crowns, & more.',
    },
    {
      name: 'Emergency',
      route: `${route.markets}?service=EMGNCY`,
      image: content.servicesImages[3],
      description: 'Urgent appointments for pain, cracked teeth & more.',
    },
  ]

  return (
    <Layout banner={banner}>
      <Hero content={content} />

      <ScrollAnimate>
        <Container maxWidth={false}>
          <Box
            component="section"
            sx={{
              pt: { xs: 4.5, sm: 6, lg: 0 },
              pb: { xs: 6, md: 12 },
            }}
          >
            <Typography
              component="h2"
              variant="h3"
              sx={{
                pt: { xs: 3, md: 5 },
                mb: 5,
                fontSize: { xs: '2rem', md: '2.5rem' },
                color: colors.core.copper[600],
                borderTop: `3px solid ${colors.core.grey[700]}`,
              }}
            >
              {content.servicesHeading}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridRowGap: { xs: 40, sm: 64 },
                gridColumnGap: { sm: 24, xl: 40 },
                gridTemplateColumns: { sm: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' },
              }}
            >
              {services.map((service) => (
                <ServiceItem {...service} key={service.name} mdUp={mdUp} />
              ))}
            </Box>
          </Box>
        </Container>
      </ScrollAnimate>

      <ScrollAnimate>
        <Box
          component="section"
          sx={{
            pt: { xs: 4, md: 0 },
            pb: { xs: 3, md: 10, xl: 12.25 },
          }}
        >
          <ReviewsModule
            reviews={content.reviews}
            reviewHeading={content.reviewHeading}
          />
        </Box>
      </ScrollAnimate>

      <Container maxWidth="xl" disableGutters>
        <Box
          sx={{
            display: { sm: 'grid' },
            mx: { sm: 3, md: 8 },
            gridTemplateColumns: {
              sm: '1fr 1fr',
            },
            pb: { sm: 5 },
            mb: { sm: 3, md: 8 },
            borderTop: { sm: thickBorderStyles.borderTop },
            borderBottom: { sm: `1px solid ${colors.core.grey[700]}` },
            overflow: 'hidden',
          }}
        >
          <Box
            component="section"
            sx={[
              {
                mx: { xs: 3, sm: 0 },
                position: 'relative',
                maxWidth: { sm: 448, md: 540, xl: 672 },
                zIndex: 1,
              },
              !smUp && thickBorderStyles,
            ]}
          >
            <Typography
              component="h2"
              variant="h3"
              sx={[
                baseHeadingStyles,
                {
                  letterSpacing: '-0.02em',
                  pt: { xs: 3, md: 5 },
                  pb: 3,
                  px: 0,
                },
              ]}
            >
              {content.mobileAppHeading}
            </Typography>
            <Box>
              <Typography
                sx={[baseBodyStyles, { mb: 4, letterSpacing: '-0.02em' }]}
              >
                {content.mobileAppBody}
              </Typography>
            </Box>
            <Link
              href={APP_STORE_URL}
              onClick={() =>
                mixpanel.track('“Marketing - Apple Appstore Badge Selected”')
              }
              sx={{ display: 'inline-block' }}
              underline="none"
              target="_blank"
              aria-label="Apple App Store (opens new window)"
              rel="noopener noreferrer"
            >
              <Image
                style={{
                  display: 'block',
                }}
                src={appleAppStoreBadge}
                alt="Apple App Store badge"
              />
            </Link>
          </Box>
          <Box
            sx={{
              position: 'relative',
              minHeight: { xs: 462, sm: 360, md: 448, lg: 576, xl: 736 },
              mr: { xs: -6, sm: 'unset' },
              mt: { xs: -8, sm: -3 },
            }}
          >
            <Image
              src={'https:' + content.mobileAppScreens.fields.file.url}
              alt={content.mobileAppScreens.fields.title}
              style={{
                objectFit: largePhoneUp ? 'contain' : 'cover',
              }}
              fill
              sizes={`(max-width: ${theme.breakpoints.values.sm}px) 100vw, 50vw`}
            />
          </Box>
        </Box>
      </Container>

      <StudioImageCarousel images={content.smallOfficeImages} />

      <ScrollAnimate>
        <Container maxWidth="xl">
          <Box
            component="section"
            sx={[
              {
                display: { sm: 'flex' },
                justifyContent: { sm: 'space-between' },
                mt: { xs: 5, md: 8 },
                mb: { xs: 8, md: 14 },
              },
              thickBorderStyles,
            ]}
          >
            <Typography
              component="h2"
              variant="h3"
              sx={[
                baseHeadingStyles,
                {
                  maxWidth: { xs: 290, md: 400, xl: 'none' },
                  py: { md: 5 },
                },
              ]}
            >
              {content.officeHeading}
            </Typography>
            <div>
              <Typography
                sx={[
                  baseBodyStyles,
                  {
                    display: 'flex',
                    mb: { xs: 3, md: 0 },
                    py: { sm: 3, md: 5 },
                    pr: { sm: 7, md: 0 },
                    pl: { sm: 0 },
                    maxWidth: { md: 544 },
                  },
                ]}
              >
                {content.officeBody}
              </Typography>

              {mdUp ? <CTAButton long sx={ctaStyles} /> : <BookButtonRow />}
            </div>
          </Box>
        </Container>
      </ScrollAnimate>

      <ScrollAnimate>
        <StudioLocationModule image={content.locationsImage} markets={markets}>
          <Typography
            component="h2"
            variant="h3"
            sx={[
              baseHeadingStyles,
              {
                pb: { xs: 2, md: 4 },
              },
            ]}
          >
            {content.locationsHeading}
          </Typography>
          <Typography sx={[baseBodyStyles, { mb: 3 }]}>
            {content.locationsBody}
          </Typography>
        </StudioLocationModule>
      </ScrollAnimate>

      <ImageGrid list={content.gridImages} altList={content.gridImagesAlt} />

      <Container maxWidth="xl" disableGutters>
        <ScrollAnimate>
          <Box
            component="section"
            sx={{
              pt: { xs: 5, md: 8 },
              px: { xs: 3, md: 0 },
              pb: { xs: 10, md: 8 },
              mx: { md: 8 },
            }}
          >
            <Typography
              component="h3"
              variant="h3"
              sx={[
                baseHeadingStyles,
                thickBorderStyles,
                {
                  pt: { xs: 3, md: 5 },
                  pb: { xs: 5, md: 8 },
                },
              ]}
            >
              {content.dentistsHeading}
            </Typography>

            <RTBList list={content.care} />

            <Link href="/about">Learn more</Link>
          </Box>
        </ScrollAnimate>
      </Container>

      <Container maxWidth="xl" disableGutters>
        <ScrollAnimate>
          <Box
            component="section"
            sx={{
              pt: 0,
              px: { xs: 3, md: 0, xl: 8 },
              pb: { xs: 5, md: 8 },
              mx: { md: 8 },
            }}
          >
            <Box
              sx={{
                display: { xs: 'grid', sm: 'flex' },
                gridTemplateColumns: '1fr 1fr',
                gridGap: 24,
                justifyContent: { sm: 'space-between' },
              }}
            >
              {content.pressImages.map((c) => (
                <Box
                  sx={{
                    height: { xs: 40, sm: 20, lg: 30 },
                  }}
                  key={c.fields.file.fileName}
                >
                  <Image
                    src={'https:' + c.fields.file.url}
                    alt={c.fields.title}
                    width={c.fields.file.details.image.width}
                    height={c.fields.file.details.image.height}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      width: 'auto',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </ScrollAnimate>

        <section>
          <ScrollAnimate>
            <ThemeProvider theme={darkTheme}>
              <InsuranceChecker
                heading={content.insuranceHeading}
                body={content.insuranceBody}
                markets={markets}
                sx={{ mx: { md: 8 } }}
              />
            </ThemeProvider>
          </ScrollAnimate>
        </section>
      </Container>

      <Container maxWidth="xl" disableGutters>
        <ScrollAnimate>
          <Box
            component="section"
            sx={{
              pt: { xs: 4, md: 8 },
              px: { xs: 3, md: 0 },
              pb: { xs: 7, md: 8 },
              mx: { md: 8 },
            }}
          >
            <Typography
              component="h3"
              variant="h3"
              sx={[
                baseHeadingStyles,
                thickBorderStyles,
                {
                  pt: { xs: 3, md: 5 },
                  pb: { xs: 5, md: 8 },
                },
              ]}
            >
              {content.perksHeading}
            </Typography>

            <RTBList
              list={content.perks}
              even={content.perks.length % 2 === 0}
            />

            {!mdUp && <BookButtonRow />}
          </Box>
        </ScrollAnimate>
      </Container>

      <Container maxWidth="xl" disableGutters>
        <Box
          component="section"
          sx={{
            pt: { xs: 7, md: 10, xl: 12.25 },
            px: { xs: 3, md: 8 },
            pb: { xs: 16, md: 17, xl: 22.5 },
          }}
        >
          <ScrollAnimate>
            <Typography
              component="h2"
              variant="h2"
              sx={[
                baseHeadingStyles,
                {
                  fontSize: { xs: '2.7rem', md: '4rem' },
                  mr: { sm: 6 },
                  mb: { xs: 5, md: 8 },
                  pb: 0,
                  maxWidth: { sm: 500, lg: 650 },
                  lineHeight: 1.4,
                },
              ]}
            >
              {content.tagline}
            </Typography>
          </ScrollAnimate>
          <ScrollAnimate>
            {mdUp ? <CTAButton long sx={ctaStyles} /> : <BookButtonRow />}
          </ScrollAnimate>
        </Box>
      </Container>

      <Box
        sx={{
          height: '45px',
          width: '100%',
          position: 'relative',
        }}
      >
        <Image
          src={patternGreen}
          alt="Tend pattern"
          fill
          style={{
            objectFit: 'cover',
          }}
        />
      </Box>

      {content.seoMetadata && <Meta {...content.seoMetadata.fields} />}
      <OrganizationJsonLd
        type="MedicalOrganization"
        name="Tend"
        url="https://www.hellotend.com"
        contactPoint={[
          {
            telephone: phoneNumbers.default,
            contactType: 'customer service',
            email,
            areaServed: 'US',
          },
        ]}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="Tend"
        url="https://www.hellotend.com"
        sameAs={[
          'https://www.facebook.com/hellotend',
          'https://twitter.com/tenddental',
          'https://www.instagram.com/hello_tend',
          'https://www.linkedin.com/company/tenddental',
        ]}
      />
      <InsuranceCheckerDialog />
    </Layout>
  )
}

export const getStaticProps = async () => {
  const entry = await marketingClient.getEntry<TypeHomepageFields>(
    process.env.NEXT_PUBLIC_CTF_ENTRIES_HOMEPAGE,
    {
      include: 2,
    },
  )
  const { fields: content } = entry

  const markets = (
    await marketingClient.getEntries<TypeMarketFields>({
      content_type: 'market',
      select: [
        'fields.name',
        'fields.slug',
        'fields.location',
        'fields.bookingEnabled',
      ],
    })
  ).items.map((item) => item.fields)

  const studios = (
    await marketingClient.getEntries<TypeStudioFields>({
      content_type: 'studio',
      select: [
        'fields.name',
        'fields.slug',
        'fields.market',
        'fields.address',
        'fields.location',
        'fields.openingDate',
        'fields.bookingEnabled',
      ],
      'fields.virtual': false,
    })
  ).items.map((item) => item.fields)

  const studiosByMarketSlug = groupBy(studios, (s) => s.market.fields.slug)
  const marketsWithStudioCount = markets
    .map((market) => ({
      ...market,
      numberOfStudios: market.bookingEnabled
        ? studiosByMarketSlug[market.slug].length
        : 0,
    }))
    .sort((a, b) => (a.numberOfStudios < b.numberOfStudios ? 1 : -1))

  const banner = content.banner?.fields || null

  return {
    props: {
      content,
      banner,
      markets: marketsWithStudioCount,
    },
    revalidate: defaultRevalidateSeconds,
  }
}

export default Index
