import { Box } from '@mui/material'
import Image from 'next/legacy/image'
import { FunctionComponent } from 'react'
import { nantes } from 'lib/fonts'
import { colors } from 'lib/root'
import { toUSDString } from 'lib/utils'
import zIndexes from 'lib/zIndexes'
import breezyPromotionDark from '../public/breezy-promotion-dark.svg'
import breezyPromotionLight from '../public/breezy-promotion-light.svg'
import patternGreen from '../public/pattern_green.png'

interface Props {
  text: string
  discount: number
  light?: boolean
}

const hasDiscount = (amount = 0, text = null) => {
  const hasAmount = !!amount && amount > 0
  const hasText = !!text

  return hasAmount && hasText
}

const PromoBadge: FunctionComponent<Props> = ({
  text,
  discount,
  light = false,
}) => {
  if (!hasDiscount(discount, text)) {
    return <></>
  }

  const img = light ? breezyPromotionLight : breezyPromotionDark
  const discountMessage = `${toUSDString(discount)} off`

  return (
    <Box
      sx={{
        position: 'absolute',
        right: {
          xs: 118,
        },
        bottom: {
          xs: 118,
          md: 96,
        },
        zIndex: zIndexes.section + 20,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          height: 128,
          width: 128,
          '& > span:first-child': {
            borderRadius: 50,
          },
        }}
      >
        <Image src={patternGreen} alt="Tend pattern" layout="fill" />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          height: 128,
          width: 128,
        }}
      >
        <Image src={img} alt={discountMessage} layout="fill" />
        <Box
          sx={{
            fontFamily: nantes.style.fontFamily,
            color: light ? 'text.secondary' : 'common.white',
            fontStyle: 'italic',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 128,
            width: 128,
            transform: 'rotate(-13.78deg)',
          }}
        >
          <Box
            /* this is for a11y purposes ot give text bg color if image changes, this needs to change */
            sx={{
              ...(!light && {
                backgroundColor: colors.core.green[700],
              }),
            }}
          >
            <Box
              component="p"
              sx={{ margin: 0, fontSize: '1.25rem', fontWeight: 700 }}
            >
              {discountMessage}
            </Box>
            <Box
              component="p"
              sx={{ margin: 0, fontSize: '0.875rem', fontWeight: 400 }}
            >
              {text}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PromoBadge
