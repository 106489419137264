import { Theme, useMediaQuery } from '@mui/material'
import { sendGTMEvent } from '@next/third-parties/google'
import { parseCookies, setCookie } from 'nookies'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { GEO_REGION, ONE_YEAR } from 'lib/constants'
import { colors } from 'lib/root'
import useUtmParams from 'components/hooks/useUtmParams'
import InsuranceChecker, { InsuranceCheckerState } from './InsuranceChecker'
import TendDialog from './TendDialog'

interface InsuranceCheckerDialogProps {
  heading?: string
  body?: string
  initialOpen?: boolean
  autoOpen?: boolean
  autoOpenDelay?: number
}

export interface InsuranceCheckerDialogHandle {
  handleOpen: () => void
}

const InsuranceCheckerDialog = forwardRef<
  InsuranceCheckerDialogHandle,
  InsuranceCheckerDialogProps
>(
  (
    {
      heading = 'Curious if insurance will cover your exam?',
      body = 'We can tell you in just a few seconds.',
      initialOpen = false,
      autoOpen = true,
      autoOpenDelay = 5000,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(initialOpen)
    const [state, setState] = useState<InsuranceCheckerState>('initial')
    const smDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

    const cookies = parseCookies()
    const utmParams = useUtmParams()

    const { utm_source: utmSource, utm_medium: utmMedium } = utmParams
    const isFriendbuyReferral =
      utmSource === 'friendbuy' && utmMedium === 'referral'
    const region = cookies[GEO_REGION]

    const allowedToSeePopup = !['KY', 'TN'].includes(region)
    const hasSeenPopup = cookies.insuranceDialogFilledOut === 'true'
    const willNotSeePopup =
      !allowedToSeePopup || hasSeenPopup || isFriendbuyReferral

    useImperativeHandle(ref, () => ({
      handleOpen: () => setIsOpen(true),
    }))

    useEffect(() => {
      if (autoOpen && !willNotSeePopup) {
        const timer = setTimeout(() => {
          setIsOpen(true)
        }, autoOpenDelay)

        return () => clearTimeout(timer)
      }
    }, [autoOpen, willNotSeePopup, autoOpenDelay])

    useEffect(() => {
      if (isOpen && !willNotSeePopup) {
        sendGTMEvent({
          event: 'Insurance_Popup_Fired',
        })
      }
    }, [isOpen, willNotSeePopup])

    useEffect(() => {
      if (!state || state === 'initial' || state === 'loading') {
        return
      }

      setCookie(null, 'insuranceDialogFilledOut', 'true', {
        maxAge: ONE_YEAR / 2,
        path: '/',
      })
    }, [state])

    const handleStateChange = (state: InsuranceCheckerState) => {
      setState(state)
    }

    const handleClose = () => setIsOpen(false)

    const fullScreen =
      smDown && (state === 'outResult' || state === 'badResult')

    return (
      <TendDialog
        open={isOpen}
        fullScreen={fullScreen}
        onClose={handleClose}
        styles={{
          closeButtonSx: [
            {
              fontSize: '4rem',
              top: { xs: -80, sm: 0 },
              right: { xs: -20, sm: 0 },
            },
            (state !== 'initial' || smDown) && {
              color: colors.core.paper,
            },
          ],
          paperSx: { overflow: 'visible' },
        }}
      >
        <InsuranceChecker
          heading={heading}
          body={body}
          isDialog
          onStateChange={handleStateChange}
          isFullScreen={fullScreen}
        />
      </TendDialog>
    )
  },
)

export default InsuranceCheckerDialog
